<template>
  <div class="e-content">
    <div id="Loading">
      <h1>Loading</h1>
      <p>페이지 이동이 있을 때 마다 loading 창이 렌더링됩니다.
      </p>

      <b-card>
        <div class="px-3 mb-2 clearfix">
          <b-img class="loading-img" width="300"
                 height="200" center src="@/assets/images/outcomes/loading.png" alt="Center image"></b-img>
        </div>
        <br/>
        <b-card-text>로딩 창은 css로 구현합니다.</b-card-text>
        <b-button
            class="btn btn-warning"
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-1"
            @click="visible = !visible"
        >
          CSS 코드
        </b-button>

        <b-collapse id="collapse-1" v-model="visible" class="mt-2">
          <div class="card-code">
              <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
                {{ codeLoadingCss }}
                </code>
              </pre>
          </div>
        </b-collapse>
        <br/><br/>
        <b-card-text>로딩 창 렌더링은 두 가지 방법으로 구현할 수 있습니다.</b-card-text>
        <b-card-text> 1. 페이지가 새로고침 되었을 때</b-card-text>
        <b-card-text> public/index.html 파일에 태그를 입력합니다.</b-card-text>
        <b-card-text> 상단 CSS 코드는 📁 public/loader.css에 작성합니다.</b-card-text>
        <h5>📁 public/index.html</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeLoadingIndex }}</code></pre>
        </div>
        <b-card-text> 2. 페이지 이동할 때 마다</b-card-text>
        <b-card-text> App.vue 파일에 태그를 입력합니다.</b-card-text>
        <b-card-text> 상단 CSS 코드는 📁 css/custom.css에 작성합니다.</b-card-text>
        <b-card-text>mounted 단계에서 isLoading()함수가 처음 실행되고, route 객체를 이용해 컴포넌트 이름이 다를 시 store 상태가 true로 변해 setInterval 함수가 실행됩니다.</b-card-text>
        <h5>📁 App.js</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeLoadingApp }}</code></pre>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BImg, BButton, BCollapse, BLink} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {
  codeLoadingCss, codeLoadingIndex, codeLoadingApp
} from './code'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BImg,
    BButton,
    BCollapse,
    BLink,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['Loading'],
      visible: false,
      codeLoadingCss,
      codeLoadingIndex,
      codeLoadingApp
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
  },
}
</script>

<style>
@media (max-width: 493px) {
  .loading-img {
    width: 100%;
    min-width: 100px
  }
}
</style>